import { usePaymentStore } from './payment.store'

import { paymentBillingGet } from '../api/paymentBillingGet.api'

export async function billingLoad() {
  const store = usePaymentStore()
  const billing = await paymentBillingGet()
  if (!billing) return false
  store.hasPurchase = billing.hasPurchase
  store.subscriptionIds = billing.subscriptionIds
  store.cards = billing.cards
  return true
}
